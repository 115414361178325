<template>
	<div>
		<!-- Applicant Profile Section -->
		<vcl-facebook v-show="loading" />
		<div v-show="!loading">
			<b-card
				border-variant="primary"
				:header="$t('Application Profile Data', 'Application Profile Data')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>
			<b-card>
				<div v-for="(row, index) in listingProfileData" :key="index" class="row">
					<b-col cols="4" class="text-14 font-weight-light pt-2"> 
						{{ $t(row.text, row.text) }}
					</b-col>
					<b-col cols="2" style="color: green; padding-left: 60px" class="text-14 text-center">
						<b>{{ $t(row.value, row.value) }}</b>
					</b-col>
				</div>
			</b-card>

			<b-card
				border-variant="primary"
				:header="$t('Application Farm Data', 'Application Farm Data')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>

			<b-card>
				<div v-for="(row, index) in listingFarmData" :key="index" class="row">
					<b-col cols="4" class="text-14 font-weight-light pt-2">
						{{ $t(row.text, row.text) }}
					</b-col>
					<b-col cols="2" style="color: green; padding-left: 60px" class="text-14 text-center">
						<b>{{ row.value }}</b>
					</b-col>
				</div>
			</b-card>
			<!-- Applicant Profile Section -->
			<b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />

			<div class="box-finalscore">
				<b-row align-v="center">
					<b-col cols="3">
						<div style="width: 120px !important; margin-top: -30px" class="text-25">
							<b>{{ $t("Final Score", "Final Score") }}</b>
						</div>
					</b-col>
					<b-col>
						<b-row class="text-center">
							<b-col>
								<b class="text-25">{{ $t(prediction, prediction) }}</b>
								<br />
								{{ $t("Total Score", "Total Score") }}
							</b-col>
							<b-col>
								<div style="width:100px; !important">
									<VueSvgGauge
										:start-angle="-110"
										:end-angle="110"
										:value="Number(score) * 100"
										:separator-step="1"
										:min="0"
										:max="4"
										:gauge-color="[
											{ offset: 0, color: '#347AB0' },
											{ offset: 100, color: '#8CDFAD' },
										]"
										:scale-interval="0.1"
									>
										<div class="inner-text">
											<span>{{ parseFloat(score) * 100 }}%</span>
										</div>
									</VueSvgGauge>
								</div>
								<br />
								<div style="width: 100px !important; margin-top: -20px">
									{{ $t("Maximum Points", "Maximum Points") }}
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
			listingFarmData: {
				type: Array,
				require: true,
				default: () => [],
			},
			listingProfileData: {
				type: Array,
				require: true,
				default: () => [],
			},
			prediction: {
				type: String,
				default: "",
			},
			loading: {
				type: Boolean,
				require: true,
			},
			score: {
				type: String,
				default: "0",
			},
		},
	}
</script>
<style>
	.inner-text {
		max-width: 190px;
		color: red;
		font-size: 40px;
		padding-top: 50px;
		padding-left: 10px;
	}
</style>
